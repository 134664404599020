import { useEffect, useState, useRef } from 'react'
import { Screens } from '../../model'
import { ProgressBar } from '../../components/ProgressBar'
import clsx from 'clsx'
import { useGetSkinByLocation } from '../../utils/common'

interface Text {
  first: string | JSX.Element
  second: string
}

const afterLoginText = {
  first: (
    <>
      Виконується підключення
      <br /> до ліцензованого казино...
    </>
  ),
  second: 'Виконується налаштування...',
}
const waitingCoefText = {
  first: 'Відправляю запит до ChatGPT...',
  second: 'Аналізую раунд...',
}

const progressDuration = 7000
const timerStep = 100

interface Props {
  setScreen: (screen: Screens) => void
  loadingAfterScreen: Screens
  isDisabled: boolean
}

const getText = (loadingAfterScreen: Screens): Text => {
  if (loadingAfterScreen === Screens.login) return afterLoginText
  return waitingCoefText
}

export const LoadingScreen: React.FC<Props> = ({
  setScreen,
  loadingAfterScreen,
  isDisabled,
}) => {
  const timer = useRef(0)
  const startTime = useRef(0)
  const endTime = useRef(0)
  const [progressTime, setProgressTime] = useState(0)
  const [text, setText] = useState<string | JSX.Element>(
    getText(loadingAfterScreen).first,
  )

  const [skin] = useGetSkinByLocation()

  useEffect(() => {
    startTime.current = Date.now()
    endTime.current = Date.now() + progressDuration
    setProgressTime(Date.now())
  }, [])

  useEffect(() => {
    if (!timer.current) {
      const interval = window.setInterval(() => {
        setProgressTime((value) => {
          if (value < endTime.current) {
            if (endTime.current - value <= progressDuration / 2) {
              setText(getText(loadingAfterScreen).second)
            }
            return Date.now()
          }
          window.clearInterval(timer.current)
          timer.current = 0
          if (loadingAfterScreen === Screens.main) {
            setScreen(Screens.calculateRate)
          } else setScreen(Screens.main)
          return endTime.current
        })
      }, timerStep)
      timer.current = interval
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={clsx('column loading-screen', isDisabled && 'disabled')}>
      <p className="text__regular">{text}</p>
      <ProgressBar
        containerClass="oracle__progress-container"
        fillerClass={clsx('oracle__progress-filler', skin.extraClass)}
        completed={
          progressTime < startTime.current
            ? 0
            : ((progressTime - startTime.current) / progressDuration) * 100
        }
      />
    </div>
  )
}
