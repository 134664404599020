interface Props {
  onClick: () => void
  label: string
  extraClass?: string
  icon?: React.ReactNode
  disabled?: boolean
}

export const AppButton: React.FC<Props> = ({
  onClick,
  label,
  extraClass,
  icon,
  disabled,
}) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={`oracle__button ${extraClass ?? ''}`}
  >
    {icon && <span className="oracle__icon">{icon}</span>}
    {label}
  </button>
)
