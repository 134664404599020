import { strategiesModal } from '../../../../constants'
import strategiesPreview from '../../../../assets/img/strategiesPreview.png'
import { RefObject, useState } from 'react'
import ReactPlayer from 'react-player/youtube'

export const MetaCrashStrategiesContent = (
  contentRef: RefObject<HTMLDivElement>,
) => {
  const [YouTubePlaying, setYouTubePlaying] = useState(false)

  return (
    <div ref={contentRef} className="content">
      <br />
      <div className="text">
        Я дуже раджу ознайомитись з розділом “Як користуватися АІ “Jet Winner”,
        перед тим як починати читати цей розділ.
      </div>
      <div className="video">
        <ReactPlayer
          url={strategiesModal.content.videoURL}
          width="100%"
          height="100%"
          controls
          playing={YouTubePlaying}
          onClickPreview={() => {
            setYouTubePlaying(true)
          }}
          light={
            <img src={strategiesPreview} alt="thumbnail" className="preview" />
          }
        />
      </div>
      <div className="text bold">
        Важливо! Це розділ зі стратегіями гри. Він буде постійно оновлюватися.
        Тому, рекомендую регулярно читати тут інформацію.
      </div>
      <br />
      <div className="text">
        Чому потрібно оновлення стратегій?
        <br />
        Бо гра запрограмована так, що може розпізнавати схожі дії користувачів
        та фіксувати їх, як підозрілі. Відповідно, разом з адаптацією гри до
        користувачів, ми адаптуємо АІ до виграшу в нових умовах.
      </div>
      <br />
      <div className="content-title-wrapper">
        <h3 className="title">Стратегії</h3>
      </div>
      <div className="text">
        Спочатку про сам алгоритм гри. Він побудований таким чином, що
        коефіцієнти для виграшу чергуються від низьких до високих і навпаки. А
        тому, якщо вирахувати правильний момент, то можна забрати високий
        коефіцієнт і, завдяки цьому, заробити велику суму грошей.
      </div>
      <div className="content-title-wrapper">
        <h3 className="title">
          Стратегія гри в Meta Crash з вірогідністю виграшу від 70% до 80%
        </h3>
      </div>
      <ul className="text">
        <li>
          робіть ставку у максимальному розмірі 20-30% відсотків від суми на
          балансі.
        </li>
        <li>
          стежте за закономірністю та повторами невеликих коефіцієнтів у розмірі
          від х1 до х2. Бо, після них, у вас є можливість забрати коефіцієнт від
          х3 до х4 та вище.
        </li>
        <li>
          завжди звертайте увагу на історію раундів та аналізуйте ті
          коефіцієнти, які вже були.
        </li>
      </ul>
    </div>
  )
}
