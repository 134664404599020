export const LoginScreenTgLink = 'https://telegram.org'
export const MainScreenTgLink = 'https://t.me/+wFPiVCRiThcyNGNi'
export const ErrorPageTgLink = 'https://telegram.org'

export const strategiesModal = {
  modalTitle: 'Стратегії гри',
  buttonTitle: 'Ще стратегії',
  content: {
    title: 'Ділимося стратегіями щодня',
    textBeforeVideo:
      'Саме така різноплановість бота і виділяє його на тлі своїх конкурентів, адже раніше більшість «розумних» помічників орієнтувалася на більш конкретний і обмежений список завдань. На даний момент нейромережі були згодом масиви даних, датовані лише 2020 роком, так що до поточного інтернету автоматичного доступу нейромережа не має.',
    videoURL: 'https://www.youtube.com/watch?v=3ao7Z8duDXc',
  },
}

export const howToUseModal = {
  buttonTitle: 'Як користуватися AІ',
  content: {
    title: 'Що таке ChatGPT і на що він здатний',
    textBeforeVideo:
      'Останні кілька тижнів новини тріщать цим AI, який пише дипломи, програмує, відповідає на запитання та розповідає анекдоти, а за швидкістю зростання обігнав навіть TikTok. У цій статті я розповім, як майже безкоштовно зареєструватися в ChatGPT.',
    videoURL: 'https://www.youtube.com/watch?v=3ao7Z8duDXc',
    videoDetail: 'Детальна відеоінструкція',
  },
}

export const errorPage = {
  title: 'Помилка',
  subtitle: 'AІ втратив синхронізацію з вашим акаунтом',
  info: {
    p1: 'Вам слід було зареєструвати НОВИЙ акаунт за посиланням, яке надав telegram-бот. А також зробити хоча б одну ставку в грі',
    p2: 'Будь ласка, виконайте обидва умови, після чого доступ буде відновлено автоматично.',
  },
  buttonLabel: 'Відновити доступ',
}
