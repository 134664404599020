import { useState } from 'react'
import { AppButton } from '../../../components/AppButton'
import { StrategiesModal } from './StrategiesModal'
import { strategiesModal } from '../../../constants'

interface Props {
  disabled: boolean
}

export const StrategiesButton: React.FC<Props> = ({ disabled }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  return (
    <>
      <AppButton
        onClick={openModal}
        label={strategiesModal.buttonTitle}
        extraClass="secondary"
        disabled={disabled}
      />
      {isOpen && <StrategiesModal open={isOpen} onClose={closeModal} />}
    </>
  )
}
