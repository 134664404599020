import { io, Socket } from 'socket.io-client'

export const SocketConnect = (): Socket =>
  io(process.env.REACT_APP_WEBSOCKET_AVIATOR_URL ?? '', {
    transports: ['websocket'],
  })

export const initAviatorGameSocket = (
  socket: Socket,
  onRoundStart: (id: string) => void,
  onStartOfFlight: () => void,
  onEndOfFlight: () => void,
): void => {
  socket.on('aviator_start_round_event', (session) => {
    onRoundStart(session.id)
    const roundStartTime = new Date(session.startDate).getTime()
    const flyStartTime = new Date(session.gameData.endOfBetsAt).getTime()
    setTimeout(() => onStartOfFlight(), flyStartTime - roundStartTime)
  })

  socket.on('aviator_end_of_flight_event', () => {
    onEndOfFlight()
  })
}
