import clsx from 'clsx'
import { useSwipeable } from 'react-swipeable'
import React, { useRef } from 'react'
import { strategiesModal } from '../../../constants'
import closeButtonSVG from '../../../assets/svgs/closeButton.svg'
import { useGetSkinByLocation } from '../../../utils/common'

interface Props {
  open: boolean
  onClose: () => void
  className?: string
}

export const StrategiesModal: React.FC<Props> = ({
  open,
  onClose,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const handlers = useSwipeable({
    onSwipedDown: () => {
      if (contentRef.current?.scrollTop === 0) {
        onClose()
      }
    },
  })

  const [skin] = useGetSkinByLocation()

  return (
    <div className={clsx(['oracle__modal', [open && 'open'], className])}>
      <div {...handlers} className="wrapper">
        <div className="header">
          <button type="button" onClick={onClose} className="close-button">
            Закрити
          </button>
          <div className="header-text">{strategiesModal.modalTitle}</div>
          <button type="button" className="close-button-icon" onClick={onClose}>
            <img src={closeButtonSVG} alt="closeButton" />
          </button>
        </div>
        {skin.strategyContent(contentRef)}
      </div>
    </div>
  )
}

StrategiesModal.defaultProps = {
  className: '',
}
