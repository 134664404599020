import { strategiesModal } from '../../../../constants'
import strategiesPreview from '../../../../assets/img/strategiesPreview.png'
import { RefObject, useState } from 'react'
import ReactPlayer from 'react-player/youtube'

export const AirJetStrategiesContent = (
  contentRef: RefObject<HTMLDivElement>,
) => {
  const [YouTubePlaying, setYouTubePlaying] = useState(false)

  return (
    <div ref={contentRef} className="content">
      <br />
      <div className="text">
        Перед тим як ви почнете читати цю рубрику, дуже раджу ознайомитись з
        розділом “Як користуватися АІ “Jet Winner””
      </div>
      <div className="video">
        <ReactPlayer
          url={strategiesModal.content.videoURL}
          width="100%"
          height="100%"
          controls
          playing={YouTubePlaying}
          onClickPreview={() => {
            setYouTubePlaying(true)
          }}
          light={
            <img src={strategiesPreview} alt="thumbnail" className="preview" />
          }
        />
      </div>
      <div className="text bold">
        Важливо! Це розділ зі стратегіями гри. Він буде оновлюватися на
        регулярній основі. Тому, рекомендую періодично читати тут інформацію.
      </div>
      <br />
      <div className="text">
        Оновлення стратегій повʼязано з тим, що гра має можливість розпізнавати
        схожі дії користувачів та фіксувати їх, як підозрілі. Саме тому, ми
        будемо намагатися оновлювати стратегії у прискореному темпі.
      </div>
      <br />
      <div className="content-title-wrapper">
        <h3 className="title">Трішки про саму гру “AirJet”</h3>
      </div>
      <div className="text">
        Сама по собі, суть гри доволі проста. Ваша ставка множиться на те число,
        на якому ви зупинилися, після чого ви забираєте свій виграш.
        <br /> <br />
        Завдяки тому, що гра побудована саме таким чином, є можливість заробити
        більше коштів за допомогою АІ. Особливо, якщо грати зі спокійною головою
        та розрахованими коефіцієнтами. Плюсом є те, що при використанні АІ в
        грі немає такого адреналіну, відповідно немає місця виникненню для
        залежності від гемблінгу.
      </div>
      <div className="content-title-wrapper">
        <h3 className="title">
          Стратегія гри в “AirJet”, яка працює від 70% до 80% випадків.
        </h3>
      </div>
      <ul className="text">
        <li>
          робити ставку потрібно у максимальному розмірі 20-30% відсотків від
          суми на балансі.
        </li>
        <li>
          звертайте увагу на історію раундів та аналізуйте ті коефіцієнти, які
          вже були.
        </li>
        <li>
          потрібно стежити за закономірністю та повторами невеликих коефіцієнтів
          у розмірі від х1 до х2, тому що після них, у вас буде можливість
          забрати коефіцієнт від х3 до х4 та вище.
        </li>
      </ul>
      <div className="text">
        Алгоритм гри побудований так, що коефіцієнти чергуються від низьких до
        високих і навпаки. Це означає, що якщо вирахувати правильний момент, то
        можна забрати високий коефіцієнт і, відповідно, заробити велику суму
        грошей.
      </div>
    </div>
  )
}
