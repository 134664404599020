import { useState, useEffect, useRef, useCallback } from 'react'
import { Logo } from '../../components/Logo'
import { Screens } from '../../model'
import { LoginScreen } from '../../screens/general/LoginScreen'
import { LoadingScreen } from '../../screens/general/LoadingScreen'
import { MainScreen } from '../../screens/general/MainScreen'
import { CalcRateScreen } from '../../screens/general/CalcRateScreen'
import { AppButton } from '../../components/AppButton'
import BackIcon from '../../assets/svgs/backBtn.svg'
import {
  useCheckConnectionIsLost,
  useGetSkinByLocation,
} from '../../utils/common'
import clsx from 'clsx'
import { useNavigate, useLocation } from 'react-router-dom'
import { SyncProgressBar } from '../../components/SyncProgressBar'

import { RECONNECT_TIMEOUT, skins } from '../../utils/constants'
import { SpinningLogo } from '../../components/SpinningLogo'
import { ToggleGameButton } from '../../components/ToggleGameButton'

const OraclePage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/${skins.airjet.url}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [screen, setScreen] = useState(Screens.login)
  const [prevScreen, setPrevScreen] = useState(Screens.login)
  const [isConnectionLost, setIsConnectionLost] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const connectTimeout = useRef(0)

  const [skin, skinToToggle] = useGetSkinByLocation()

  useCheckConnectionIsLost(setIsConnectionLost)

  const onReturnToMenu = useCallback(
    (e: Event) => {
      setScreen(Screens.main)
      navigate(`/${skin.url}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screen, skin]
  )

  // механизм, по которому при нажатии кнопки "назад", нас кидает на главную страницу
  useEffect(() => {
    if (screen === Screens.calculateRate || screen === Screens.loading) {
      window.addEventListener('popstate', onReturnToMenu, false)
    } else {
      window.removeEventListener('popstate', onReturnToMenu, false)
    }
    return () => {
      window.removeEventListener('popstate', onReturnToMenu, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screen])

  useEffect(() => {
    if (isConnectionLost) {
      if (isLoggedIn) {
        connectTimeout.current = window.setTimeout(
          () => setScreen(Screens.main),
          RECONNECT_TIMEOUT,
        )
      }
    } else if (connectTimeout.current) {
      clearTimeout(connectTimeout.current)
    }
  }, [isConnectionLost, isLoggedIn])

  useEffect(() => {
    const id = localStorage.getItem('oracle_id')
    if (id) {
      setScreen(Screens.main)
      setPrevScreen(Screens.main)
      setIsLoggedIn(true)
    }
  }, [])

  const renderScreen = (screenType: Screens): JSX.Element => {
    switch (screenType) {
      case Screens.login:
        return (
          <LoginScreen
            setScreen={setScreen}
            setPrevScreen={setPrevScreen}
            setIsLoggedIn={setIsLoggedIn}
          />
        )
      case Screens.loading:
        return (
          <LoadingScreen
            setScreen={setScreen}
            loadingAfterScreen={prevScreen}
            isDisabled={isConnectionLost}
          />
        )
      case Screens.main:
        return (
          <MainScreen
            setScreen={setScreen}
            setPrevScreen={setPrevScreen}
            isDisabled={isConnectionLost}
          />
        )
      case Screens.calculateRate:
        return (
          <CalcRateScreen
            isDisabled={isConnectionLost}
            isLoggedIn={isLoggedIn}
            setScreen={setScreen}
            setPrevScreen={setPrevScreen}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div
      className={clsx(
        'oracle__wrapper',
        skin.extraClass,
        screen === Screens.main && 'main',
        screen === Screens.calculateRate && 'rate',
      )}
    >
      {(screen === Screens.login || screen === Screens.main) && (
        <div
          className={clsx('oracle__toggle-wrapper', skinToToggle.extraClass)}
        >
          <ToggleGameButton skinToToggle={skinToToggle} />
          {screen === Screens.main && <SyncProgressBar syncPercent={80} />}
        </div>
      )}
      {screen === Screens.calculateRate && (
        <SyncProgressBar syncPercent={80} extraClass={'rate'} />
      )}
      {screen === Screens.calculateRate && (
        <AppButton
          label="Меню"
          onClick={() => {
            setScreen(Screens.main)
          }}
          icon={<img src={BackIcon} alt="" />}
          extraClass="menu"
        />
      )}
      {isConnectionLost && (
        <div className="oracle__connection-error">
          <p className="text__small">Втрачено з’єднання з інтернетом</p>
        </div>
      )}
      <div className="oracle">
        {screen === Screens.loading ? (
          <SpinningLogo logo={skin.loadIcon} />
        ) : (
          <Logo logo={skin.logo} />
        )}
        {renderScreen(screen)}
      </div>
    </div>
  )
}

export default OraclePage
