import { useState } from 'react'
import { Logo } from '../../components/Logo'
import { CalcRateScreen } from '../../screens/general/CalcRateScreen'
import {
  useGetSkinByLocation,
  useCheckConnectionIsLost,
} from '../../utils/common'
import clsx from 'clsx'
import { Screens } from '../../model'
import { SpinningLogo } from '../../components/SpinningLogo'
import { LoadingScreen } from '../../screens/general/LoadingScreen'

const RatePage: React.FC = () => {
  const [isConnectionLost, setIsConnectionLost] = useState(false)
  const [screen, setScreen] = useState(Screens.calculateRate)
  const [prevScreen, setPrevScreen] = useState(Screens.main)

  useCheckConnectionIsLost(setIsConnectionLost)

  const [skin] = useGetSkinByLocation()

  const renderScreen = (screenType: Screens): JSX.Element => {
    switch (screenType) {
      case Screens.loading:
        return (
          <LoadingScreen
            setScreen={setScreen}
            loadingAfterScreen={prevScreen}
            isDisabled={isConnectionLost}
          />
        )
      case Screens.calculateRate:
        return (
          <CalcRateScreen
            isDisabled={isConnectionLost}
            setScreen={setScreen}
            setPrevScreen={setPrevScreen}
            isRatePage
          />
        )
      default:
        return <></>
    }
  }

  return (
    <div className={clsx('oracle__wrapper', skin.extraClass)}>
      {isConnectionLost && (
        <div className="oracle__connection-error">
          <p className="text__small">Втрачено з’єднання з інтернетом</p>
        </div>
      )}
      <div className="oracle">
        {screen === Screens.loading ? (
          <SpinningLogo logo={skin.loadIcon} />
        ) : (
          <Logo logo={skin.logo} />
        )}
        {renderScreen(screen)}
      </div>
    </div>
  )
}

export default RatePage
