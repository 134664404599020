interface Props {
  className: string
}
export const ThumbsDown: React.FC<Props> = ({ className }) => {
  return (
    <svg
      className={className}
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0399 9.66701C15.8725 9.66701 16.6453 9.08065 16.7394 8.25335C16.7941 7.77188 16.6333 7.32628 16.3417 7.00051C17.1355 6.11389 16.7145 4.70184 15.5709 4.39026C15.6433 4.20745 15.6834 4.00853 15.6834 3.8007C15.6834 3.06428 15.183 2.44191 14.5043 2.25704C14.9498 1.13222 14.0545 0.0676165 12.905 0.0676165H9.41647C7.55908 0.0676165 6.28774 0.498118 5.28264 0.838466C5.19217 0.869102 5.10385 0.899007 5.01746 0.927801L5.01746 8.92991C5.08934 8.96584 5.15943 9.00042 5.22801 9.03427C5.81138 9.32218 6.28599 9.55641 6.83144 10.1019C7.73708 11.0075 8.21727 13.2335 8.21727 14.4667C8.21727 14.7615 8.45583 15 8.75059 15H9.19065C10.4007 15 11.4028 14.0303 11.4168 12.8203C11.4252 12.0892 11.2355 10.2997 10.8822 9.66701H15.0399Z"
        fill="currentColor"
      />
      <path
        d="M1.28331 9.67015H3.41653C3.71108 9.67015 3.94981 9.43138 3.94981 9.13687L3.94981 0.604107C3.94981 0.309561 3.71101 0.0707941 3.4165 0.0707941H1.28331C0.988767 0.0707941 0.75 0.309596 0.75 0.604107L0.75 9.13684C0.75 9.43138 0.988802 9.67015 1.28331 9.67015Z"
        fill="currentColor"
      />
    </svg>
  )
}

ThumbsDown.defaultProps = {
  className: '',
}
