import { RefObject } from 'react'

export enum Screens {
  'login',
  'loading',
  'main',
  'calculateRate',
  'error',
}

export enum Games {
  'airjet',
  'metacrash',
}

export interface LoginParams {
  auth: boolean
  ban: boolean | null
}

export interface SkinType {
  extraClass: string
  logo: any
  icon: any
  loadIcon: any
  url: string
  label: string
  strategyContent: (ref: RefObject<HTMLDivElement>) => JSX.Element
  howToUseTitle: string
  howToUseContent: (ref: RefObject<HTMLDivElement>) => JSX.Element
}

export type Skins = Record<keyof typeof Games, SkinType>

export interface States {
  START_ROUND: string
  START_FLY: string
  END_FLY: string
}
