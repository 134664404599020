import './style.scss'

import { BrowserRouter as Router } from 'react-router-dom'
import { GeneralRoutes } from './routes/GeneralRoutes'
// import { IndianRoutes } from './routes/IndianRoutes'

function App() {
  const getRoutes = () => {
    // const domain = window.location.host
    return <GeneralRoutes />
  }

  return (
    <Router>
      <div>{getRoutes()}</div>
    </Router>
  )
}

export default App
