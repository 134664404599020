import { Screens } from '../../model'
import { HowToUseButton } from '../../components/modals/howToUse/HowToUseButton'
import { StrategiesButton } from '../../components/modals/strategies/StrategiesButton'
import ConnectionStatusIcon from '../../assets/svgs/wifi-square.svg'
import OnlineUsersIcon from '../../assets/svgs/profile-2user.svg'
import { AppButton } from '../../components/AppButton'
import TelegramIcon from '../../assets/svgs/Telegram.svg'
import { MainScreenTgLink } from '../../constants'
import clsx from 'clsx'
import {
  useGetSkinByLocation,
  useGetRandomOnlineUsers,
} from '../../utils/common'

interface Props {
  setScreen: (screen: Screens) => void
  setPrevScreen: (screen: Screens) => void
  isDisabled: boolean
}

export const MainScreen: React.FC<Props> = ({
  setScreen,
  setPrevScreen,
  isDisabled,
}) => {
  const [skin] = useGetSkinByLocation()
  const onlineUsers = useGetRandomOnlineUsers(skin)

  return (
    <div className={clsx('column main-screen', isDisabled && 'disabled')}>
      <div className={clsx('main-info-wrapper', skin.extraClass)}>
        <div className="connection">
          <span className="title">З’єднання</span>
          <div className="info-wrapper">
            {!isDisabled && (
              <div className="icon">
                <img src={ConnectionStatusIcon} alt="connection-status icon" />
              </div>
            )}
            <div className={clsx('status', isDisabled && 'offline')}>
              {!isDisabled ? 'Встановлено' : '-'}
            </div>
          </div>
        </div>
        <div className="online">
          <div className="title">Користувачів онлайн</div>
          <div className="info-wrapper">
            {!isDisabled && (
              <div className="icon">
                <img src={OnlineUsersIcon} alt="users online icon" />
              </div>
            )}
            <div className={clsx('status', isDisabled && 'offline')}>
              {!isDisabled ? onlineUsers : '-'}
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <AppButton
            label="Запуск AІ для аналізу раунду"
            onClick={() => {
              setScreen(Screens.loading)
              setPrevScreen(Screens.main)
            }}
            extraClass={clsx(skin.extraClass, 'big')}
            disabled={isDisabled}
          />
        </div>
      </div>
      <p className="text__small aside-lines">Є питання? Ми допоможемо</p>
      <HowToUseButton disabled={isDisabled} />
      <StrategiesButton disabled={isDisabled} />
      <AppButton
        label="Підпишись"
        onClick={() => window.open(MainScreenTgLink)}
        icon={<img src={TelegramIcon} alt="" />}
        extraClass="telegram"
        disabled={isDisabled}
      />
    </div>
  )
}
