import OraclePage from '../pages/general/OraclePage'
import ErrorPage from '../pages/general/ErrorPage'
import RatePage from '../pages/general/RatePage'
import { Routes, Route } from 'react-router-dom'
import { skins } from '../utils/constants'

export const GeneralRoutes = () => {
  return (
    <Routes>
      {['/', skins.airjet.url, skins.metacrash.url].map((path) => (
        <Route path={path} element={<OraclePage />} key={path} />
      ))}
      {[`/error/${skins.airjet.url}`, `/error/${skins.metacrash.url}`].map(
        (path) => (
          <Route path={path} element={<ErrorPage />} key={path} />
        ),
      )}
      {[
        `/get-current-rate/${skins.airjet.url}`,
        `/get-current-rate/${skins.metacrash.url}`,
      ].map((path) => (
        <Route path={path} element={<RatePage />} key={path} />
      ))}
    </Routes>
  )
}
