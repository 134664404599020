import React, { useState } from 'react'
import { AppButton } from '../../../components/AppButton'
import { HowToUseModal } from './HowToUseModal'
import { howToUseModal } from '../../../constants'

interface Props {
  disabled: boolean
}

export const HowToUseButton: React.FC<Props> = ({ disabled }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const openModal = (): void => {
    setIsOpen(true)
  }

  const closeModal = (): void => {
    setIsOpen(false)
  }

  return (
    <>
      <AppButton
        onClick={openModal}
        label={howToUseModal.buttonTitle}
        extraClass="secondary"
        disabled={disabled}
      />
      {isOpen && <HowToUseModal open={isOpen} onClose={closeModal} />}
    </>
  )
}
