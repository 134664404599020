import { WsNotifier } from './WsNotifier'
import { decodeEvent } from './decoder'

enum NotifierEvents {
  CLOSE = 'close',
  OPEN = 'open',
  ERROR = 'error',
  MESSAGE = 'message',
}

enum EventTypes {
  START_GAME = 'start_game',
  CHANGE_STATE = 'change_state',
  CHANGE_COEFFICIENT = 'change_coefficient',
  END_GAME = 'end_game',
}

interface Event {
  event_type: string
  round_id: string
  state: string
}

export const initSocket = (
  notifier: WsNotifier,
  onRoundStart: (id: string) => void,
  onStartOfFlight: () => void,
  onEndOfFlight: () => void,
): void => {
  notifier.on(NotifierEvents.MESSAGE, (event: any) => {
    const decEvent = decodeEvent(event) as Event

    if (decEvent.event_type === EventTypes.START_GAME)
      onRoundStart(decEvent.round_id)

    if (decEvent.state === 'flying') onStartOfFlight()

    if (decEvent.event_type === EventTypes.END_GAME) onEndOfFlight()
  })
}
