import { useEffect, useState, useRef } from 'react'
import { AppButton } from '../../components/AppButton'
import { rate } from '../../api'
import clsx from 'clsx'
import { ThumbsUp } from '../../assets/svgs/ThumbsUp'
import { ThumbsDown } from '../../assets/svgs/ThumbsDown'
import { useNavigate } from 'react-router-dom'
import { Screens } from '../../model'
import { useGetSkinByLocation } from '../../utils/common'
import { MCSocketConnect } from '../../api/socket/metacrash/SocketConnect'
import { initSocket as MCInitSocket } from '../../api/socket/metacrash/SocketProvider'
import { Socket } from 'socket.io-client'
import { WsNotifier } from '../../api/socket/metacrash/WsNotifier'
import {
  SocketConnect as AJSocketConnect,
  initAviatorGameSocket,
} from '../../api/socket/airjet'
import { skins, roundStates } from '../../utils/constants'
import { SpinningLogo } from '../../components/SpinningLogo'

interface Props {
  isDisabled: boolean
  setScreen?: (s: Screens) => void
  setPrevScreen?: (s: Screens) => void
  isLoggedIn?: boolean
  isRatePage?: boolean
}

export const CalcRateScreen: React.FC<Props> = ({
  isDisabled,
  setScreen,
  setPrevScreen,
  isLoggedIn,
  isRatePage,
}) => {
  const [currentRate, setCurrentRate] = useState(0)
  const [selectedButton, setSelectedButton] = useState<boolean | 'unset'>(
    'unset',
  )
  const [isLoading, setIsLoading] = useState(true)
  const [roundId, setRoundId] = useState('')
  const [isEndOfFly, setIsEndOfFly] = useState(false)
  const [roundState, setRoundState] = useState(roundStates.START_FLY)
  const socket = useRef<WsNotifier | Socket | null>(null)

  const navigate = useNavigate()
  const [skin] = useGetSkinByLocation()

  const fetchRate = async () => {
    let result
    let login
    if (!isRatePage) {
      login = localStorage.getItem('oracle_id')
    }
    result = await rate(skin.url, login ?? undefined)
    if (!isEndOfFly) {
      setCurrentRate(result?.data?.rate ?? 0)
    }
    setSelectedButton('unset')
  }

  const onRoundStart = (id: string): void => {
    setRoundId(id)
    setIsEndOfFly(false)
    setRoundState(roundStates.START_ROUND)
  }

  const onEndOfFlight = () => {
    setIsEndOfFly(true)
    setRoundState(roundStates.END_FLY)
  }

  const onStartOfFlight = () => {
    setRoundState(roundStates.START_FLY)
  }

  useEffect(() => {
    if ((isLoggedIn || isRatePage) && !isDisabled) {
      if (socket.current) socket.current.close()
      if (skin.url === skins.airjet.url) {
        socket.current = AJSocketConnect()
        initAviatorGameSocket(
          socket.current,
          onRoundStart,
          onStartOfFlight,
          onEndOfFlight,
        )
      }
      if (skin.url === skins.metacrash.url) {
        socket.current = MCSocketConnect()
        MCInitSocket(
          socket.current,
          onRoundStart,
          onStartOfFlight,
          onEndOfFlight,
        )
      }
    }
    return () => {
      if (socket.current) socket.current.close()
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isLoggedIn, skin, isDisabled])

  useEffect(() => {
    if (currentRate) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRate])

  useEffect(() => {
    if (isEndOfFly) {
      setIsLoading(true)
      setCurrentRate(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEndOfFly])

  useEffect(() => {
    if (!isDisabled) {
      fetchRate().catch((e) => {
        if (!isRatePage) {
          navigate(`/error/${skin.url}`)
          localStorage.removeItem('oracle_id')
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundId, isDisabled])

  return (
    <div className={clsx('column rate-screen', isDisabled && 'disabled')}>
      <div>
        <p className="text__header">Раунд вивчений</p>
        <p className={clsx('text__state margin-top-12', skin.extraClass)}>
          {roundState}
        </p>
      </div>
      <div className="rate-block">
        <p className="text__regular">Коефіцієнт не більше</p>
        {isLoading ? (
          <SpinningLogo logo={skin.loadIcon} small />
        ) : (
          <p className="rate">{`${currentRate.toString().replace('.', ',')}x`}</p>
        )}
        <p className="text__regular">
          Ставка від банка не більше <span className="green">35%</span>
        </p>
      </div>
      <div>
        <div className="like-block">
          <AppButton
            disabled={isDisabled}
            label="Правильно"
            icon={<ThumbsUp className={'thumb-up'} />}
            onClick={() => setSelectedButton(true)}
            extraClass={clsx('secondary like', [
              selectedButton === true && 'selected',
            ])}
          />
          <AppButton
            disabled={isDisabled}
            label="Неправильно"
            icon={<ThumbsDown className={'thumbs-down'} />}
            onClick={() => setSelectedButton(false)}
            extraClass={clsx('secondary dislike', [
              selectedButton === false && 'selected',
            ])}
          />
        </div>
        <p className="text__small">
          Після закінчення раунду, будь ласка,
          <br />
          оцініть роботу AІ, це дозволить
          <br />
          йому більш точно прогнозувати
          <br />
          наступні результати.
        </p>
        <AppButton
          label="Запуск AІ для аналізу раунду"
          onClick={() => {
            if (setScreen && setPrevScreen) {
              setScreen(Screens.loading)
              setPrevScreen(Screens.main)
            }
          }}
          extraClass={clsx(skin.extraClass, 'margin-top-12')}
          disabled={isDisabled}
        />
      </div>
    </div>
  )
}
