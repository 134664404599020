import ProgressBar from './ProgressBar'
import clsx from 'clsx'
import { useGetSkinByLocation } from '../utils/common'

interface Props {
  syncPercent: number
  extraClass?: string
}

export const SyncProgressBar: React.FC<Props> = (props) => {
  const { syncPercent, extraClass } = props
  const [skin] = useGetSkinByLocation()

  return (
    <div className={clsx('oracle__sync-progress-wrapper', extraClass)}>
      <p className="sync-text">
        Sync <span className={skin.extraClass}>{`${syncPercent}%`}</span>
      </p>
      <ProgressBar
        containerClass="oracle__progress-container synchro"
        fillerClass={clsx('oracle__progress-filler synchro', skin.extraClass)}
        completed={80}
      />
    </div>
  )
}

export default ProgressBar
