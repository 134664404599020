import GPTLogo from '../assets/img/GPTLogo.webp'
import GPTIcon from '../assets/img/GPTLogoMini.webp'
import BTCLogo from '../assets/img/BTCLogo.webp'
import BTCIcon from '../assets/img/BTCicon.webp'
import GPTLoading from '../assets/img/GPTLogoLoading.webp'
import BTCLoading from '../assets/img/BTCLoading.webp'
import { States, Skins } from '../model'
import { AirJetHowToUseContent } from '../components/modals/howToUse/content/AirJet'
import { MetaCrashHowToUseContent } from '../components/modals/howToUse/content/MetaCrash'
import { AirJetStrategiesContent } from '../components/modals/strategies/content/AirJet'
import { MetaCrashStrategiesContent } from '../components/modals/strategies/content/MetaCrash'

export const skins: Skins = {
  airjet: {
    extraClass: 'air-jet',
    logo: GPTLogo,
    icon: GPTIcon,
    loadIcon: GPTLoading,
    url: 'airjet',
    label: 'AirJet',
    strategyContent: (ref) => AirJetStrategiesContent(ref),
    howToUseTitle: 'Як користуватися АІ “Jet Winner”',
    howToUseContent: (ref) => AirJetHowToUseContent(ref),
  },
  metacrash: {
    extraClass: 'meta-crash',
    logo: BTCLogo,
    icon: BTCIcon,
    loadIcon: BTCLoading,
    url: 'metacrash',
    label: 'Meta Crash',
    strategyContent: (ref) => MetaCrashStrategiesContent(ref),
    howToUseTitle: 'Як працює AI “Jet Winner”',
    howToUseContent: (ref) => MetaCrashHowToUseContent(ref),
  },
}

export const RECONNECT_TIMEOUT = 15000

export const roundStates: States = {
  START_ROUND: 'Початок нового раунду',
  START_FLY: 'Літак летить',
  END_FLY: 'Літак полетів',
}
